import About from './pages/About'
import AgencySolution from './pages/AgencySolution'
import AwkwardConvos from './pages/AwkwardConvos'
import Customers from './pages/Customers'
import DPA from './pages/DPA'
import Demo from './pages/Demo'
import EBook from './pages/EBook'
import EmploymentLawUpdates from './pages/EmploymentLawUpdates'
import EnterprisePolicyManagement from './pages/EnterprisePolicyManagement'
import HRPolicyContentServices from './pages/HRPolicyContentServices'
import Home from './pages/Home'
import ITSolution from './pages/ITSolution'
import LegalSolution from './pages/LegalSolution'
import ManagerParticipation from './pages/ManagerParticipation'
import MarketingSolution from './pages/MarketingSolution'
import Newsletter from './pages/Newsletter'
import PersonalizedHandbooks from './pages/PersonalizedHandbooks'
import PolicyCommunication from './pages/PolicyCommunication'
import Pricing from './pages/Pricing'
import Privacy from './pages/Privacy'
import PrivacyShield from './pages/PrivacyShield'
import Product from './pages/Product'
import { SignInPage, SignedOutPage } from './pages/SignIn'
import SignUp from './pages/SignUp'
import Terms from './pages/Terms'
import { InstallRipplingPage } from './pages/integrations'

// Microsite
import { routes as micrositeRoutes } from './microsite/routes'

export const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/employee-handbook-software',
    exact: true,
    component: Product,
  },
  {
    path: '/pricing',
    exact: true,
    component: Pricing,
  },
  {
    path: '/about',
    exact: true,
    component: About,
  },
  {
    path: '/legal-compliance',
    exact: true,
    component: LegalSolution,
  },
  {
    path: '/branded-employee-handbook',
    exact: true,
    component: MarketingSolution,
  },
  {
    path: '/customers',
    exact: true,
    component: Customers,
  },
  {
    path: '/employment-law-updates',
    exact: true,
    component: EmploymentLawUpdates,
  },
  {
    path: '/information-security',
    exact: true,
    component: ITSolution,
  },
  {
    path: '/employee-handbook-management',
    exact: true,
    component: AgencySolution,
  },
  {
    path: '/request-a-demo',
    exact: true,
    component: Demo,
  },
  {
    path: '/data-processing-addendum',
    exact: true,
    component: DPA,
  },
  {
    path: '/how-to-write-a-culture-first-employee-handbook',
    exact: true,
    component: EBook,
  },
  {
    path: '/manager-participation',
    exact: true,
    component: ManagerParticipation,
  },
  {
    path: '/newsletter',
    exact: true,
    component: Newsletter,
  },
  {
    path: '/personalized-handbooks',
    exact: true,
    component: PersonalizedHandbooks,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: Privacy,
  },
  {
    path: '/policy-communication',
    exact: true,
    component: PolicyCommunication,
  },
  {
    path: '/privacy-shield',
    exact: true,
    component: PrivacyShield,
  },
  {
    path: '/terms',
    exact: true,
    component: Terms,
  },
  {
    path: '/sign-in',
    exact: true,
    component: SignInPage,
  },
  {
    path: '/sign-out',
    exact: true,
    component: SignedOutPage,
  },
  {
    path: '/sign-up',
    exact: true,
    component: SignUp,
  },
  {
    path: '/awkward-hr-conversations',
    exact: true,
    component: AwkwardConvos,
  },
  {
    path: '/enterprise-policy-management',
    exact: true,
    component: EnterprisePolicyManagement,
  },
  {
    path: '/employee-handbook-hr-policy-content',
    exact: true,
    component: HRPolicyContentServices,
  },
  {
    path: '/install/rippling',
    exact: true,
    component: InstallRipplingPage,
  },
  ...micrositeRoutes,
]
